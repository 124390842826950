import React from "react"
import { Link } from "gatsby"

import SEO from "../components/seo"

const SecondPage = () => (
    <div>
      <SEO title="Info" />
      <h1>About this site</h1>
      <Link to="/">Go back to the homepage</Link>
    </div>
)

export default SecondPage
